import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Colin Reeves gave a lecture sponsored by the AAPG, RWE and Maersk at several
locations in  Europe during November and December 2011. The title of the talk
was 'Some plate-tectonic  thoughts on the early opening of the South Atlantic
Ocean'. The venues were Geneva (Nov 30),  Vienna (Dec 1), Hamburg (Dec 7) and
Copenhagen (Dec 15). The abstract of the talk may be  seen `}<a parentName="p" {...{
        "href": "/"
      }}>{`here`}</a>{`. `}</p>
    <p>{`Plate tectonics starts with the realization that the earth’s lithosphere today
is divided into a small  number of large plates with seismicity mostly confined
to their common margins.  Uniformitarianism, the foundation of geology, says
that things were always like this. But active  plate boundaries change position
over time and even minor intra-plate exceptions (e.g. the North  Sea graben) are
not negligible from an economic standpoint. `}</p>
    <PublishDate noday mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      